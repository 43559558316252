'use client';

import { MouseEvent, ReactNode, useEffect, useState } from 'react';
import { SvgHeart } from '@icons/designSystemIcons/SvgHeart';
import { SvgSunGlow } from '@icons/designSystemIcons/SvgSunGlow';
import { SvgPlusSmall } from '@icons/oldIcons/SvgPlusSmall';
import { priceFormat } from '@utils/priceFormat';
import { getDiscountedPrice } from '@utils/productCommon';
import ROUTES from '@utils/routes';
import Tracker from '@utils/tracker';
import { useCartStore } from 'app/(dashboard)/dashboard/(pages)/budgets/stores/userCartStore';
import { useSessionStore } from 'app/stores/globalStore';
import { CartItem, Product } from 'app/types/product';
import { useImageProps } from 'app/utils/common';
import { Button } from 'designSystem/Buttons/Buttons';
import { Flex } from 'designSystem/Layouts/Layouts';
import { Text } from 'designSystem/Texts/Texts';
import { isEmpty } from 'lodash';
import Image from 'next/image';
import { usePathname } from 'next/navigation';

export default function ProductCard({
  isDashboard = false,
  product,
  className = '',
  tag,
  hidePrice,
  onClick,
}: {
  isDashboard?: boolean;
  product: Product;
  className?: string;
  tag?: string;
  hidePrice?: boolean;
  onClick?: (...args: any[]) => void;
}) {
  const tracker = new Tracker();

  const pathName = usePathname();
  const { setBudgetId, currentOrigin, selectedClinic } = useSessionStore(
    state => state
  );
  const addToCart = useCartStore(state => state.addItemToCart);

  const { imgSrc, setNextImgSrc } = useImageProps(product);
  const [discountedPrice, setDiscountedPrice] = useState<0 | number>(0);

  const LANDINGS: { [key: string]: string } = {
    '/landing/ppc/holaglow': '#leadForm',
  };

  const isLanding = Object.keys(LANDINGS).includes(usePathname());

  useEffect(() => {
    if (!isEmpty(product.discounts)) {
      const discountedPrice = getDiscountedPrice(
        product,
        currentOrigin,
        selectedClinic?.id
      );

      if (discountedPrice !== null) {
        setDiscountedPrice(discountedPrice);
      }
    }
  }, [product]);

  const ProductCardWrapper = ({ children }: { children: ReactNode }) => {
    const commonProps = {
      id: 'tmevent_click_product_card',
      onClick: (event: MouseEvent<HTMLElement>) =>
        onClick
          ? onClick(event)
          : tracker.track(
              'Click',
              'ProductCard',
              product?.extraInformation?.slug
            ),
      className: `text-inherit ${className}`,
    };

    const WrapperComponent = isDashboard ? 'div' : 'a';
    const additionalProps = isDashboard
      ? {}
      : {
          href: isLanding
            ? LANDINGS[pathName]
            : `${product.type === 2 ? ROUTES.treatments : ROUTES.aestheticTreatments}/${product?.extraInformation?.slug}`,
        };

    return (
      <WrapperComponent {...commonProps} {...additionalProps}>
        {children}
      </WrapperComponent>
    );
  };

  return (
    <ProductCardWrapper>
      <div className="flex flex-col h-full overflow-hidden">
        <Flex layout="col-left" className="">
          <div className="relative w-full rounded-t-2xl bg-hg-black500/10 overflow-hidden aspect-[6/5]">
            <Image
              alt={product.title}
              src={imgSrc}
              fill
              onError={() => setNextImgSrc()}
              className="object-cover group-hover:scale-105 transition-all"
            />

            {tag && (
              <span className="absolute bottom-4 left-4 bg-white/90 rounded-full px-2 py-1 text-xs ">
                {tag}
              </span>
            )}

            {product.isPack && (
              <Flex className="absolute top-4 right-0 bg-hg-cream500 rounded-l-full p-1">
                <Image
                  alt="Pack Holaglow"
                  src="/images/filters/categories/pack.svg"
                  width={64}
                  height={64}
                  className="h-5 w-5 mr-1"
                />
                <span className="text-xs font-medium text-hg-secondary mr-1">
                  Pack
                </span>
              </Flex>
            )}

            {!isEmpty(product.tags) &&
              product.tags[0].tag &&
              product.tags[0].tag === 'Black Friday' && (
                <Flex
                  layout="row-center"
                  className="bg-hg-black text-hg-secondary rounded-full p-1 px-2 absolute bottom-3 left-3"
                >
                  <SvgSunGlow
                    height={12}
                    width={12}
                    className="text-hg-primary mr-1"
                  />
                  <Text size="xs">
                    Black
                    <span className="font-semibold font-gtUltra text-hg-secondary500">
                      Friday
                    </span>
                  </Text>
                </Flex>
              )}
            {!isEmpty(product.tags) &&
              product.tags[0].tag &&
              product.tags[0].tag === 'San Valentín' && (
                <Flex
                  layout="row-center"
                  className="bg-hg-red text-hg-pink rounded-full py-1 px-2 absolute bottom-3 left-3"
                >
                  <SvgHeart className="h-4 w-4" />
                  <Text className="text-xs text-hg-skin ml-2 font-bold font-gtUltra">
                    San Valentín
                  </Text>
                </Flex>
              )}
          </div>
        </Flex>
        <Flex
          layout="col-left"
          className="p-3 flex-grow bg-white rounded-b-2xl z-10"
        >
          <Text
            as="h2"
            className={`mb-2 font-semibold ${isDashboard ? 'text-md' : 'text-lg'}`}
          >
            {product.title}
          </Text>
          {!isDashboard && (
            <Text size="xs" className="text-hg-black500 mb-4">
              {product.longDescription}
            </Text>
          )}
          <Flex
            layout="row-left"
            className="mt-auto items-start justify-between w-full"
          >
            {isDashboard && (
              <Button
                size="sm"
                type="primary"
                customStyles="py-2 px-2"
                onClick={e => {
                  e.stopPropagation();
                  addToCart(
                    product as CartItem,
                    selectedClinic && selectedClinic.id
                  );
                  setBudgetId('');
                }}
              >
                <SvgPlusSmall className="h-4 w-4" />
              </Button>
            )}
            {!hidePrice && (
              <Flex className="gap-2 w-full justify-end items-baseline">
                {product.hasUpgrades && (
                  <Text className="text-sm text-hg-black500">Desde</Text>
                )}
                {discountedPrice > 0 && (
                  <Text className="text-sm line-through text-hg-black500">
                    {priceFormat(product.price)} €
                  </Text>
                )}

                <Text
                  className={`text-hg-secondary font-semibold ${isDashboard ? 'text-xl' : 'text-lg'}`}
                >
                  {priceFormat(
                    discountedPrice ? discountedPrice : product.price
                  )}{' '}
                  €{' '}
                </Text>
              </Flex>
            )}
          </Flex>
        </Flex>
      </div>
    </ProductCardWrapper>
  );
}
